import React from "react"
import SEO from "../components/SEO"
import Faq from "../components/FAQ"
import { Container, Row, Col } from "reactstrap"

class Faqs extends React.Component {
  render() {
    return (
      <>
        <SEO
          title="digital marketing Faqs - brillea"
        />
        <div className={"header_section header_section_faqs"}>
          <Container>
            <Row>
              <Col className={"section_medium"}>
                <h1 className={"text-center"}>Frequently asked questions</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="grey_bk">
          <Faq />
        </div>
      </>
    )
  }
}

export default Faqs
