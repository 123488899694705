import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FaqWrapper from './FaqWrapper'

const Faq = ({title}) => {
  const data = useStaticQuery(graphql`
      {
        faqs:allContentfulFaqCategory(filter: {faqPageVisible: {eq: true}})
      {
        edges {
          node {
            id: contentful_id
            node_locale
            title
            faqPageVisible
            faqList {
              id: contentful_id
              title
              desc {
                desc
              }
            }
          }
        }
      }
    }
  `)
  return <FaqWrapper catLabel={true} data={data} title={title}/>
}

export default Faq